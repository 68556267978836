import ajax from './modules/ajax';
// import height from './modules/height';
import load from './modules/load';
import sphere from './modules/sphere';
// import animations from './modules/animations';
import about from './modules/about';

load();
ajax();
// height();
sphere();
// animations();
about();
